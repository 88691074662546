.bio {
  margin-bottom: var(--main-header-margin-bottom);
  justify-content: space-between;
  display: flex;
}

.contact-info {
  flex-direction: column;
  font-style: normal;
  display: flex;
}

.contact-info .name {
  font-size: var(--main-header-size);
  margin-top: 0;
  margin-bottom: 1rem;
}

.contact-info .title {
  color: var(--secondary-text-color);
  font-size: 1.2rem;
}

.contact-info svg {
  --size: 1.2rem;
  vertical-align: middle;
  width: var(--size);
  height: var(--size);
  margin-right: .2rem;
}

.portrait {
  --size: 128px;
  width: var(--size);
  height: var(--size);
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 50%;
}

.featured-projects {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2.5rem;
  display: flex;
}

.featured-projects .project {
  flex-direction: column;
  align-items: center;
  width: 43%;
  display: flex;
}

.featured-projects .project .thumbnail {
  --size: 128px;
  width: var(--size);
  height: var(--size);
  background-color: var(--theme-color);
  border-radius: 20%;
  box-shadow: .4rem .3rem;
}

.featured-projects .project .title {
  letter-spacing: .2ch;
  text-align: center;
  font-size: 1rem;
}
/*# sourceMappingURL=index.8f09ac60.css.map */

.bio {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--main-header-margin-bottom);
}

.contact-info {
  display: flex;
  flex-direction: column;
  font-style: normal;

  .name {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: var(--main-header-size);
  }

  .title {
    font-size: 1.2rem;
    color: var(--secondary-text-color);
  }

  svg {
    --size: 1.2rem;
    vertical-align: middle;
    width: var(--size);
    height: var(--size);
    margin-right: 0.2rem;
  }
}

.portrait {
  --size: 128px;
  width: var(--size);
  height: var(--size);
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 50%;
}

.featured-projects {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  justify-content: space-evenly;

  .project {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 43%;

    .thumbnail {
      --size: 128px;
      width: var(--size);
      height: var(--size);
      background-color: var(--theme-color);
      border-radius: 20%;
      box-shadow: 0.4rem 0.3rem 0;
    }

    .title {
      font-size: 1rem;
      letter-spacing: 0.2ch;
      text-align: center;
    }
  }
}
